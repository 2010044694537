<template class="html">
	<router-view id="app">
	</router-view>
</template>

<script>
	export default {
		// 生命周期
		data() {
			return {}
		},
		methods: {},
		beforeCreate() {
			// console.log('创建前');
		},
		created() {
			// console.log('创建');
		},
		beforeMount() {
			// console.log('挂载前');
		},
		mounted() {
			// console.log('挂载');
		},
		beforeUpdate() {
			// console.log('修改前');
		},
		updated() {
			// console.log('修改');
		},
		beforeDestroy() {
			// console.log('销毁前');
		},
		destroyed() {
			// console.log('销毁');
		}

	}
</script>

<style>
	/* 1rem = 16px */
	html {
		font-size: 16px;
	}

	body {
		padding: 0;
		margin: 0;
		font-size: 1rem;
		font-family: Helvetica Neue, Helvetica, sans-serif;
	}

	body::-webkit-scrollbar {
		height: 10px;
		width: 10px;
	}

	/* ---全局颜色设置--- */
	body::-webkit-scrollbar-track {
		background: #EDEDED;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	}

	body::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: #535353;
	}

	body {
		color: #333333;
		background-color: #3C3C3C;
	}

	p,
	ul,
	li,
	input {
		margin: 0;
		padding: 0;
	}

	/* 文字 */
	p {
		color: #DEDEDE;
	}

	/* 菜单列 */
	li {
		color: #DEDEDE;
	}

	/* 按键 */
	span {
		color: #696969;
		background-color: #3B3B3B;
	}

	/* 输入 */
	input {
		color: #DEDEDE;
	}


	input {
		/* 选中光标颜色 */
		caret-color: #DEDEDE;
		background-color: #282828;
	}



	/* input提示语 */
	input::-webkit-input-placeholder {
		color: #696969;
		/* text-align: right; */
	}

	/* ---头部菜单--- */
	.header {
		background-color: #282828;
	}

	/* 菜单选中 */
	.nav-li:hover {
		background-color: #4f4f4f;
	}

	/* 更多菜单选中 */
	.nav-more-li:hover {
		background-color: #4f4f4f;
	}

	.nav-li:hover .nav-more-list {
		background-color: #282828;
	}

	/* 搜索 */
	.search input {
		background-color: #3B3B3B;
	}

	/* ---首页--- */
	/* Banner-hover */
	.banner:hover .banner-key {
		background-color: rgba(115, 115, 115, 0.7);
	}


	/* ---Mask--- */
	/* Mask-Box */
	.login-box {
		background-color: #3c3c3c;
	}

	/* Mask关闭 */
	.close-reg {
		color: #B9B9B9;
	}

	/* Mask关闭-转动 */
	.close-reg:hover {
		color: #984949;
	}

	/*  Mask-input */
	.login-form input {
		color: #696969;
	}

	/* Mask-登录input */
	.login-but input {
		border-color: #3E3E3E;
		color: #696969;
		background-color: #282828;
	}

	/* Mask-其他 */
	.login-other p {
		color: #696969;
		border-bottom-color: #696969;
	}

	/* ---用户中心--- */
	/* 用户头像-hover-背景 */
	.user-avatar:hover .avatar-chang {
		background-color: rgba(0, 0, 0, 0.5);
	}

	/* 用户头像-hover-文字 */
	.avatar-chang {
		color: #DEDEDE;
	}

	/* 用户菜单 */
	.menu li:hover {
		background: #4F4F4F;
	}

	/* 用户菜单-选中 */
	.check {
		background: #4F4F4F;
	}

	/* 保存按键 */
	.submit-button {
		background-color: #282828;
		color: #DEDEDE;
	}

	/* 下载清单-下载 */
	.download-list :last-child p:hover {
		color: #399fff;
	}

	/* 下载清单-暂无记录 */
	.download-none {
		color: #DEDEDE;
	}

	/* 充值中心-银 */
	.vip-silver,
	.vip-num1 {
		background-color: #DEDEDE;
	}

	/* 充值中心-金 */
	.vip-gold,
	.vip-num2 {
		background-color: #D4B82A;
	}


	img {
		margin: 0;
		padding: 0;
		border: 0;
		height: 5rem;
		width: 5rem;
	}

	ul,
	li {
		list-style-type: none
	}





	span,
	p {
		user-select: none;
	}

	a {
		text-decoration: none;
		color: #FAFAFA;
	}

	a:visited {
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
	}

	a:active {
		text-decoration: none;
	}

	.content {
		width: 75rem;
		margin: 0 auto;
	}

	.block {
		display: block !important;
	}
</style>
