<template>
  <div class="footer">
    <p>
      <a @click="go('index')">Nes</a> 运行时间{{ time }}
    </p>
    <p>
      <a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2024024162号-1</a>
    </p>
    <!-- <p>{{time}}</p> -->
  </div>
</template>

<script>
import Util from "../utils/util.js"

export default {
  data() {
    return {
      time: ""
    }
  },
  mounted() {
    // TODO 底部时间转换
    var timestamp = parseInt(new Date().getTime() / 1000);
    this.time = Util.timeFormat(timestamp - 1511995200, 'Y年m月d日H时i分s秒');
    setInterval(() => {
      timestamp++;
      this.time = Util.timeFormat(timestamp - 1511995200, 'Y年m月d日H时i分s秒');
    }, 1000);
  },
  methods: {
    go(link) {
      this.$router.push(link);
    },
  }
}
</script>

<style>
.footer {
  margin: 1rem 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer p {
  margin: 0 10px;
  padding: 0;
  line-height: 2rem;
}
</style>
