// App
import App from './App.vue';
import Vue from 'vue';

// Axios
import axios from 'axios';
import util_api from '../common/utils/api.js';
Vue.prototype.$api = axios.create(util_api);

// Route
import Route from 'vue-router';
Vue.use(Route);
import Index from '../pages/index/index.vue';
import Test from '../pages/index/test.vue';
import Info from '../pages/info/info.vue';
import User from '../pages/user/index.vue';
import NotFoundComponent from '../common/template/not-found.vue';
const router = new Route({
  mode: 'history',
  routes: [
	{ path: '/', component: Index },
    { path: '/index', component: Index },
	{ path: '/user', component: User },
	{ path: '/info', component: Info },
	{ path: '/test', component: Test },
	{ path: '*', component: NotFoundComponent}
  ]
});

// 弃用
// import Global from '../common/utils/global.js';
// Vue.prototype.$global = Global;

// Config 阻止启动生产消息
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router
}).$mount('#app')