<template>
	<div class="null">
		404哦
	</div>
</template>

<script>
	import Util from "../utils/util.js"
	export default {
		data() {
			return {
				time: ""
			}
		},
		mounted() {
			// TODO 底部时间转换
			var timestamp = parseInt(new Date().getTime() / 1000);
			this.time = Util.timeFormat(timestamp - 1511995200, 'Y年m月d日H时i分s秒');
			setInterval(() => {
				timestamp++;
				this.time = Util.timeFormat(timestamp - 1511995200, 'Y年m月d日H时i分s秒');
			}, 1000);
		},
		methods: {
			go(link) {
				this.$router.push(link);
			},
		}
	}
</script>

<style>
	.null{
		color: #fff;
		height: 10rem;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
