<template>
	<div>
		<xjHeader ref="header"></xjHeader>
		<div class="content user-content">
			<div ref='silder' class="user-left">
				<div class="user-info">
					<div class="user-avatar">
						<img :src="profile.userInfo.avatar">
						<div class="avatar-chang">更换头像</div>
					</div>
					<p>{{nickname}}</p>
				</div>
				<div class="user-menu">
					<ul class="menu">
						<li :class="ShowMenuId==1?'check':''" @click="changMenu(1)">个人设置</li><!-- 用户资料 个人设置等-->
						<li :class="ShowMenuId==2?'check':''" @click="changMenu(2)">修改密码</li>
						<li :class="ShowMenuId==3?'check':''" @click="changMenu(3)">下载清单</li>
						<li :class="ShowMenuId==4?'check':''" @click="changMenu(4)">获取次数</li><!-- （分享次数） -->
						<li :class="ShowMenuId==5?'check':''" @click="changMenu(5)">充值中心</li><!-- （包月）（包次） -->
						<li :class="ShowMenuId==6?'check':''" @click="changMenu(6)">安全退出</li>
					</ul>
				</div>
			</div>


			<div class="user-right">
				<div v-show="ShowMenuId==1">

					<div class="group-heard">
						<p>个人资料</p>
					</div>

					<div class="group-content">
						<p class="content-title">
							账号：
						</p>
						<div class="content-info">
							<p>{{profile.userInfo.account}}</p>
						</div>
					</div>

					<div class="group-content">
						<p class="content-title">
							等级：
						</p>
						<div class="content-info">
							<p>{{profile.userInfo.level}}</p>
						</div>
					</div>

					<div class="group-content">
						<p class="content-title">
							昵称：
						</p>
						<div class="content-info">
							<input type="text" v-model.trim="profile.userInfo.nickname" placeholder="昵称"></input>
						</div>
					</div>
					<div class="group-content">
						<p class="content-title">
							邮箱：
						</p>
						<div class="content-info">
							<input type="text" v-model.trim="profile.userInfo.email" placeholder="邮箱"></input>
						</div>
					</div>
					<div class="group-content">
						<p class="content-title">
							电话：
						</p>
						<div class="content-info">
							<input type="text" v-model.trim="profile.userInfo.mobile" placeholder="电话"></input>
						</div>
					</div>

					<div class="group-content">
						<p class="content-title">
							生日：
						</p>
						<div class="content-info">
							<p>{{profile.userInfo.birthday}}</p>
						</div>
					</div>
					<div class="group-content">
						<p class="content-title">
							个签：
						</p>
						<div class="content-info">
							<input type="text" v-model.trim="profile.userInfo.bio" placeholder="个签"></input>
						</div>
					</div>

					<div class="group-heard">
						<p>主题设置</p>
					</div>
					<div class="group-content">
						<p class="content-title">
							主题：
						</p>
						<div class="content-info">
							<!-- 预设主题-->
							<p>默认</p>
						</div>
					</div>
					<div class="group-content">
						<p class="content-title">
							自定义：
						</p>
						<div class="content-info">
							<!-- 背景色 标题栏 字体颜色 搜索栏 -->
							<p>背景色 标题栏 字体颜色 搜索栏</p>
						</div>
					</div>

					<div class="group-heard">
						<p>推送设置</p>
					</div>
					<div class="group-content">
						<p class="content-title">
							开启推送：
						</p>
						<div class="content-info">
							<p>是否</p>
						</div>
					</div>

					<div class="group-content">
						<span class="submit-button">
							保存
						</span>
					</div>

				</div>
				<div v-show="ShowMenuId==2">
					<!-- <div class="group-heard">
						<p>修改密码</p>
					</div> -->
					<div class="group-content2">
						<p class="content-title">
							原密码：
						</p>
						<div class="content-info">
							<input type="password" v-model.trim="oldPassword" placeholder="原密码"></input>
						</div>
					</div>
					<div class="group-content2">
						<p class="content-title">
							新密码：
						</p>
						<div class="content-info">
							<input type="password" v-model.trim="newPassword" placeholder="新密码"></input>
						</div>
					</div>
					<div class="group-content2">
						<p class="content-title">
							确认密码：
						</p>
						<div class="content-info">
							<input type="password" v-model.trim="checkPassword" placeholder="确认密码"></input>
						</div>
					</div>

					<div class="group-content2">
						<span class="submit-button">
							保存
						</span>
					</div>
				</div>
				<div v-show="ShowMenuId==3">
					<ul v-show="downloadList.length>0" class="download-list">
						<li>序号</li>
						<li>名称</li>
						<li>类型</li>
						<li>时间</li>
						<li></li>
					</ul>
					<ul v-show="downloadList.length>0" v-for="item in downloadList" :key="item.id" class="download-list">
						<li>{{item.id}}</li>
						<li>{{item.name}}</li>
						<li>{{item.type}}</li>
						<li>{{item.time}}</li>
						<li>
							<p>下载</p>
						</li>
					</ul>
					<div v-show="downloadList.length==0" class="download-none">暂无记录</div>
				</div>
				<div v-show="ShowMenuId==4">
					<div class="group-heard">
						<p>我的次数</p>
					</div>

					<div class="group-content nums">
						<p class="content-title">
							基础次数：
						</p>
						<div class="content-info">
							<p>{{userSum.base_num}}</p>
						</div>
						<p class="content-title">
							包月次数：
						</p>
						<div class="content-info">
							<p>{{userSum.month_num}}</p>
						</div>
						<p class="content-title">
							分享次数：
						</p>
						<div class="content-info">
							<p>{{userSum.share_num}}</p>
						</div>
						<p class="content-title">
							充值次数：
						</p>
						<div class="content-info">
							<p>{{userSum.pay_num}}</p>
						</div>
					</div>

					<div class="group-heard">
						<p>获取次数</p>
					</div>
					<div class="group-content block num-li">
						<p>
							将链接分享给未注册的好友，好友注册成功后将获得免费的下载次数，上不封顶
						</p>
						<p>
							{{userSum.share_link}} (点击复制)
						</p>
					</div>

					<div class="group-heard">
						<p>次数说明</p>
					</div>
					<div class="group-content block num-li">
						<p>
							基础次数：每天获得的免费下载次数，不累计，当日清空
						</p>
						<p>
							包月次数：月卡获得的每天次数，不累计，当日清空
						</p>
						<p>
							分享次数：分享好友获得的次数，永久累计
						</p>
						<p>
							充值次数：直接充值获得的次数，永久累计
						</p>
						<p>
							扣减顺序：基础次数 > 包月次数 > 分享次数 > 充值次数
						</p>
					</div>
				</div>
				<div v-show="ShowMenuId==5">
					<!-- 会员卡片 -->
					<div class="vip-month">
						<div class="vip-silver">
							<div class="month-price"></div>
							<div class="month-num"></div>
						</div>
						<div class="vip-gold">
							<div class="month-price"></div>
							<div class="month-num"></div>
						</div>
					</div>
					<!-- 次数 -->
					<div class="vip-nums">
						<div class="vip-num1"></div>
						<div class="vip-num2"></div>
					</div>
					<!-- 自选次数 -->
					<div class="vip-num"></div>
					<!-- 支付方式 -->
					<div class="vip-pay"></div>
					<!-- 提交 -->
					<div class="vip-submit"></div>
				</div>
				<div v-show="ShowMenuId==6"></div>
			</div>
		</div>
		<xjFooter></xjFooter>
	</div>
</template>

<script>
	import xjHeader from '../../common/template/xj-header.vue';
	import xjFooter from '../../common/template/xj-footer.vue';
	export default {
		components: {
			xjHeader,
			xjFooter
		},
		data() {
			return {
				profile: {},
				nickname: '',
				ShowMenuId: 1,
				// 修改密码数据
				oldPassword: "",
				newPassword: "",
				checkPassword: "",
				// 下载清单数据
				downloadList: [{
						id: 1,
						name: 'Sunshine',
						type: '基础次数',
						time: '2020-12-12 10:12'
					},
					{
						id: 2,
						name: 'SunshineSunshineSunshineSunshineSunshineSunshine',
						type: '包月次数',
						time: '2020-12-12 10:12'
					},
					{
						id: 3,
						name: 'Sunshine',
						type: '分享次数',
						time: '2020-12-12 10:12'
					},
					{
						id: 4,
						name: 'Sunshine',
						type: '充值次数',
						time: '2020-12-12 10:12'
					}
				],
				// 获取次数数据
				userSum: {
					base_num: 3,
					month_num: 10,
					share_num: 50,
					pay_num: 100,
					share_link:"http://img.xuanjian.work/share=xx"
				},
				payList:{
					month:[
						{type:'silver',pay:5,num:15},
						{type:'gold',pay:10,num:25}
					],
					nums:[
						{type:'num',pay:1,num:10},
						{type:'num',pay:10,num:150}
					],
					num:10
				}
			}
		},
		created(e) {
			var user = JSON.parse(localStorage.getItem('user'));
			if (!user) {
				this.$router.showLogin = true;
				this.$router.push("index");
			}
			this.nickname = user.userInfo.nickname;
			this.profile = user;
		},
		mounted(e) {
			// 计算页面高度
			var clientHeight = localStorage.getItem('clientHeight');
			if(clientHeight<600){
				this.$refs.silder.style.height = '40rem';
			}else{
				var userHeight = (clientHeight - 8.5 * 16) + "px";
				this.$refs.silder.style.height = userHeight;
			}
			
		},
		methods: {
			changMenu(id) {
				if(id==6){
					this.$refs.header.remove();
				}
				if (id == this.ShowMenuId) return false;
				this.ShowMenuId = id;
				// 注意id为3时请求下载数据
			}
		}
		// computed:{
		// 	ShowMenu:function(){
		// 		return{
		// 			check:
		// 		}
		// 	}
		// },

	}
</script>

<style>
	.user-content {
		display: flex;
	}

	.user-menu,
	.user-info {
		display: flex;
		/* justify-content: center; */
		flex-direction: column;
		align-items: center;
	}

	.user-left {
		width: 15%;
		border-right: 1px #5D5D5D solid;
	}

	.user-avatar {
		position: relative;
		border-radius: 50%;
		cursor: pointer;
	}

	.user-avatar:hover .avatar-chang {
		display: block;
	}

	.avatar-chang {
		display: none;
		/* 基于其父级以上最近的relative定位，若无则相对于document定位 */
		position: absolute;
		text-align: center;
		line-height: 7.5rem;
		font-size: .6rem;
		width: 5rem;
		height: 5rem;
		margin: 0;
		padding: 0;
		top: 0;
		border-radius: 50%;
	}

	.user-info {
		border-bottom: 1px #5D5D5D solid;
		margin: 1rem 1rem;
	}

	.user-info p {
		margin: .5rem 0 1rem;
		font-size: .6rem;
	}

	.user-info img {
		border-radius: 50%;
	}

	.menu {
		width: 100%;
	}

	.menu li {
		margin: .5rem 1rem;
		cursor: pointer;
		text-align: center;
		line-height: 1.5rem;
		height: 1.5rem;
		padding: .3rem;
	}

	.user-right {
		width: 80%;
		padding: 1rem 2rem;
	}

	.group-heard {
		border-bottom: 1px #5D5D5D solid;
	}

	.group-heard p {
		margin-bottom: .5rem;
	}

	.group-heard:not(:first-child) {
		margin-top: 1rem;
	}

	.group-content {
		display: flex;
		margin-top: 1rem;
		margin-left: 2rem;
	}

	.group-content2 {
		display: flex;
		margin-top: 1rem;
	}

	.group-content p,
	.group-content2 p {
		line-height: 21px;
	}

	.content-title {
		margin-right: .2rem;
	}

	.content-info input {
		border: 1px #282828 solid;
		border-radius: 1px;
		height: 100%;
	}

	.content-info p {
		line-height: 21px;
	}

	.submit-button {
		height: 2rem;
		display: flex;
		align-items: center;
		padding: 0 .5rem;

	}

	.submit-button span {
		cursor: pointer;
	}

	.download-list {
		display: flex;
		margin-top: .2rem;
	}

	.download-list li {
		flex: 1;
		line-height: 21px;
		word-break: keep-all;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: default;
	}

	.download-list :first-child,
	.download-list :last-child,
	.download-list :nth-child(3) {
		flex: .2;
	}

	.download-list :nth-child(4) {
		flex: .4;
	}

	.download-list :last-child p {
		cursor: pointer;
		width: 2rem;
	}

	.nums .content-title:not(:first-child) {
		margin-left: 1rem;
	}
	.num-li p{
		margin-top: .2rem;
	}
</style>

<style>
	.vip-month,.vip-nums{
		display: flex;
		justify-content: space-around;
		width: 100%;
	}
	.vip-nums{
		margin-top: 2rem;
	}
	.vip-silver{
		height: 11rem;
		width: 20rem;
		background-image: url(../../assets/images/card/silver.png);
	}
	.vip-gold{
		height: 11rem;
		width: 20rem;
		background-image: url(../../assets/images/card/gold.png);
	}
	.vip-num1{
		height: 3rem;
		width: 20rem;
	}
	.vip-num2{
		height: 3rem;
		width: 20rem;
	}
</style>