<template>
	<div>
			<div>456456</div>
			<xjFooter></xjFooter>
	</div>
</template>

<script>
	import xjFooter from '../../common/template/xj-footer.vue';
	import Vue from 'vue';
	export default{
		components:{
			xjFooter
		},
		mounted(){
			// this.$global.init();
			// console.log(this.$global.user);
		}
	}
</script>

<style>
</style>
