export default {
	  // `method` 是创建请求时使用的方法
	  method: 'post', // 默认是 get
	
	  // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
	  // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
	  baseURL: 'http://fa.xuanjian.work/api/',
	
	  // `transformRequest` 允许在向服务器发送前，修改请求数据
	  // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
	  // 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
	  // 对 data 进行任意转换处理
	  // transformRequest: [function (data) {
	  //   return data;
	  // }],
	
	  // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
	  transformResponse: [function (data) {
	    return data;
	  }],
	
	  // `headers` 是即将被发送的自定义请求头
	  headers: {'X-Requested-With': 'XMLHttpRequest'},
	
	  // `params` 是即将与请求一起发送的 URL 参数
	  // 必须是一个无格式对象(plain object)或 URLSearchParams 对象
	  // params: {
	    // ID: 12345
	  // },
	
	  // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
	  // 如果请求话费了超过 `timeout` 的时间，请求将被中断
	  timeout: 10000,
	
	  // `withCredentials` 表示跨域请求时是否需要使用凭证
	  withCredentials: false, // 默认的
	
	  // `auth` 表示应该使用 HTTP 基础验证，并提供凭据
	  // 这将设置一个 `Authorization` 头，覆写掉现有的任意使用 `headers` 设置的自定义 `Authorization`头
	  // auth: {
	  //   username: 'janedoe',
	  //   password: 's00pers3cret'
	  // },
	
	  // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
	  responseType: 'json', // 默认的
	
	  // `onUploadProgress` 允许为上传处理进度事件
	  // onUploadProgress: function (progressEvent) {
	    // 对原生进度事件的处理
	  // },
	
	  // `onDownloadProgress` 允许为下载处理进度事件
	  // onDownloadProgress: function (progressEvent) {
	    // 对原生进度事件的处理
	  // },
	
	  // `maxContentLength` 定义允许的响应内容的最大尺寸
	  // maxContentLength: 2000,
	
	  // `validateStatus` 定义对于给定的HTTP 响应状态码是 resolve 或 reject  promise 。如果 `validateStatus` 返回 `true` (或者设置为 `null` 或 `undefined`)，promise 将被 resolve; 否则，promise 将被 rejecte
	  // validateStatus: function (status) {
	  //   return status >= 200 && status < 300; // 默认的
	  // },
	
	  // `maxRedirects` 定义在 node.js 中 follow 的最大重定向数目
	  // 如果设置为0，将不会 follow 任何重定向
	  // maxRedirects: 1, // 默认的
	
	  // `cancelToken` 指定用于取消请求的 cancel token
	  // （查看后面的 Cancellation 这节了解更多）
	  // cancelToken: new CancelToken(function (cancel) {
	  // })
}