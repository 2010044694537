<template >
	<div>
		<!-- @consolexx='consolexx' -->
		<!-- <xjHeader :Menu="HeaderMenu" :Logo="HeaderLogo"></xjHeader> -->
		<xjHeader></xjHeader>
		<div class="content">
			<!-- <button @click="tt">1111</button> -->
			<div class="banner">
				<!-- 先做普通图片，在做canvas -->
				<!-- vue 动态渲染组件 transition  -->
				<div class="banner-item">
					<img ref="changimg" v-show="item.id==ShowBannerId" v-for="item in BannerImage" :key="item.id" :src="item.imgsrc">
				</div>
				<a class="banner-left banner-key" @click="prev"></a>
				<a class="banner-rigth banner-key" @click="next"></a>
			</div>
			<div class="images">
				<ul>
					<li v-for="(img,index) of Images" :key="index">
						<a @click="go(img.link)">
							<img :src="img.imgsrc" :alt="img.desc">
						</a>
					</li>
				</ul>
			</div>
		</div>
		<xjFooter></xjFooter>
	</div>
</template>

<script>
	import xjHeader from '../../common/template/xj-header.vue';
	import xjFooter from '../../common/template/xj-footer.vue';
	export default {
		components: {
			xjHeader,
			xjFooter
		},
		data() {
			return {
				BannerImage: [{
						id: 1,
						imgsrc: require("../../assets/images/banner/banner1.jpg")
					},
					{
						id: 2,
						imgsrc: require("../../assets/images/banner/banner2.jpg")
					},
					{
						id: 3,
						imgsrc: require("../../assets/images/banner/banner3.jpg")
					}
				],
				ShowBannerId: 1,
				// 请求图片
				ImagesList: [{
						id: 1,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img1.jpg"),
						time: "2020.02.22"
					},
					{
						id: 2,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img2.jpg"),
						time: "2020.02.22"
					},
					{
						id: 3,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img3.jpg"),
						time: "2020.02.22"
					},
					{
						id: 4,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img4.jpg"),
						time: "2020.02.22"
					},
					{
						id: 5,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img5.jpg"),
						time: "2020.02.22"
					},
					{
						id: 6,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img6.jpg"),
						time: "2020.02.22"
					},
					{
						id: 7,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img7.jpg"),
						time: "2020.02.22"
					},
					{
						id: 8,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img8.jpg"),
						time: "2020.02.22"
					},
					{
						id: 9,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img9.jpg"),
						time: "2020.02.22"
					},
					{
						id: 10,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img10.jpg"),
						time: "2020.02.22"
					},
					{
						id: 11,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img11.jpg"),
						time: "2020.02.22"
					},
					{
						id: 12,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img12.jpg"),
						time: "2020.02.22"
					},
					{
						id: 13,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img13.jpg"),
						time: "2020.02.22"
					},
					{
						id: 14,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img14.jpg"),
						time: "2020.02.22"
					},
					{
						id: 15,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img15.jpg"),
						time: "2020.02.22"
					}, {
						id: 16,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img16.jpg"),
						time: "2020.02.22"
					},
					{
						id: 17,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img17.jpg"),
						time: "2020.02.22"
					},
					{
						id: 18,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img18.jpg"),
						time: "2020.02.22"
					},
					{
						id: 19,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img19.jpg"),
						time: "2020.02.22"
					},
					{
						id: 20,
						desc: "阳光",
						link: "/info",
						imgsrc: require("../../assets/images/img/img20.jpg"),
						time: "2020.02.22"
					}
				],
				// 展示图片
				Images: []
			}
		},
		mounted() {
			// this.$global.header.HeaderMenu = this.HeaderMenu;
			// this.$global.header.HeaderLogo = this.HeaderLogo;
			
			// 获取页面可视区域
			var clientHeight = `${document.documentElement.clientHeight}`;
			localStorage.setItem('clientHeight', clientHeight);
			console.log("页面可视区域: " + clientHeight);

			// 创建图片区域
			// 除底部及图片区域外占比 441-21-(3*16) = 372px
			// 图片区域占比 383.61px
			var contentHeigth = clientHeight - 372;
			this.contentHeigth = contentHeigth
			console.log("图片可视区域: " + contentHeigth);

			// 加载图片
			this.addimg(contentHeigth);

			// 页面滚动监听
			window.addEventListener("scroll", this.handleScroll);

			// 创建图片
			// var imgs = [];
			// for (let i = 0; i < 10; i++) {
			// 	for (let k = 0; k < this.Images.length; k++) {
			// 		imgs.push(this.Images[k])
			// 	}
			// }
			// this.Images = imgs;


			// 请求
			// this.$api.get('demo/test', {
			// 		params: {
			// 			text: "This Test"
			// 		}
			// 	})
			// 	.then(function(response) {
			// 		console.log(response);
			// 	})
			// 	.catch(function(error) {
			// 		console.log(error);
			// 	});

			// 定时
			// setTimeout(() => {
			// 	this.tt();
			// }, 2000)
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			go(link) {
				console.log(link);
				if (link) {
					this.$router.push(link);
				}
			},
			handleScroll() {
				var top = document.documentElement.scrollTop
				var contentHeigth = top + this.contentHeigth;
				// Math.ceil向上取整
				if (Math.ceil(contentHeigth / 384) > this.sum) {
					this.addimg(contentHeigth);
				}
			},
			addimg(heigth) {
				var sum = Math.ceil(heigth / 384);
				this.sum = sum;
				var imgs = [];
				if(sum<=(this.ImagesList.length/5)){
					for (let i = 0; i < sum*5; i++) {
						imgs.push(this.ImagesList[i])
					}
					this.Images = imgs;
				}
				
			},
			prev() {
				console.log(this.$refs.changimg.style);
				if (this.ShowBannerId > 1) {
					this.ShowBannerId = this.ShowBannerId - 1;
				} else {
					this.ShowBannerId = 3;
				}
			},
			next() {
				if (this.ShowBannerId < 3) {
					this.ShowBannerId = this.ShowBannerId + 1
				} else {
					this.ShowBannerId = 1;
				}
				console.log(this.$refs.changimg.style);
			}
			// 接受子组件事件
			// consolexx(e) {
			// 	console.log(e);
			// }
		}
	}
</script>

<style>

	/* .bannerimages-enter-active{
		  transition: all 1s ease;
	} */
	/* .banner-images-leave-active {
		transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	} */

	.banner {
		width: 75rem;
		height: 21.25rem;
		position: relative;
		margin-bottom: .5rem;
	}

	.banner-item,
	.banner-item img {
		width: 100%;
		height: 100%;
	}

	.banner:hover .banner-key {
		opacity: .5;
	}

	.banner-left,
	.banner-rigth {
		width: 2rem;
		height: 6rem;
		/* 元素定位是基于父元素的定位来决定的 */
		position: absolute;
		background-size: 2rem 2rem;
		background-position: center;
		background-repeat: no-repeat;
		z-index: 2;
		top: 7.625rem;
		opacity: 0;
	}

	.banner-left {
		background-image: url(../../assets/images/icon/left.png);
	}

	.banner-rigth {
		right: 0px;
		background-image: url(../../assets/images/icon/rigth.png);
	}

	.images {
		min-width: 75rem;
	}

	.images ul {
		display: flex;
		flex-wrap: wrap;
		margin-right: -1rem;
	}

	.images li {
		/* 1:0.618 */
		width: 14.2rem;
		height: 22.9756rem;
		margin: 0 1rem 1rem 0;
		list-style-type: none;
	}

	.images img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
</style>
