var dateFormat = function(timestamp, formats) {
	/**
	 * @param {Object} value
	 * timestamp
	 * 千分位秒
	 * formats格式包括
	 * / * - ：+ ，’ 年月日时分秒
	 */
	formats = formats || 'Y-m-d';

	var zero = function(value) {
		if (value < 10) {
			return '0' + value;
		}
		return value;
	};

	var myDate = timestamp ? new Date(timestamp) : new Date();

	var year = myDate.getFullYear();
	var month = zero(myDate.getMonth() + 1);
	var day = zero(myDate.getDate());

	var hour = zero(myDate.getHours());
	var minite = zero(myDate.getMinutes());
	var second = zero(myDate.getSeconds());

	return formats.replace(/Y|m|d|H|i|s/ig, function(matches) {
		return ({
			Y: year,
			m: month,
			d: day,
			H: hour,
			i: minite,
			s: second
		})[matches];
	});
};

var timeFormat = function(timestamp, formats) {
	/**
	 * @param {Object} value
	 * timestamp
	 * 秒
	 */
	formats = formats || 'Y-m-d';

	var year = parseInt(timestamp/31536000);
	var month = parseInt((timestamp-year*31536000)/2628000);
	var day = parseInt((timestamp-year*31536000-month*2628000)/87600);

	var hour = parseInt((timestamp-year*31536000-month*2628000-day*87600)/3600);
	var minite = parseInt((timestamp-year*31536000-month*2628000-day*87600-hour*3600)/60);//
	var second = timestamp-year*31536000-month*2628000-day*87600-hour*3600-minite*60;//取余

	return formats.replace(/Y|m|d|H|i|s/ig, function(matches) {
		return ({
			Y: year,
			m: month,
			d: day,
			H: hour,
			i: minite,
			s: second
		})[matches];
	});
}

export default {
	dateFormat,
	timeFormat
}
