<template>
	<div>
		<xjHeader></xjHeader>
		<div class="content info-content">
			<div class="content-left">
				<div class="content-title">
					<p>
						{{content.title}}
					</p>
				</div>
				<div class="content-item">
					<p>{{content.time}}</p>
					<p>
						<!-- <svg t="1607607944280" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13068"
						 width="100" height="100">
							<path d="M928.99328 544.09216c0.54272-2.688 1.3568-6.71232 1.3568-11.52 0-5.00736-0.8448-9.1904-1.08544-9.69216-0.18432-2.47808-1.024-6.99904-1.81248-9.37472-0.20992-0.5888-0.4864-1.20832-0.72704-1.80224-0.42496-1.36192-0.87552-2.69312-1.38752-3.74784-76.8768-168.13568-242.37568-281.14432-411.78112-281.14432-169.37472 0-334.86336 112.90112-411.38688 280.13056-0.9216 1.81248-1.57696 3.55328-1.98144 5.06368-0.1792 0.49664-0.39424 0.96768-0.55808 1.40288-1.5104 4.43392-1.54112 7.51616-1.42336 6.71744-0.71168 3.2512-1.36192 9.71776-1.36192 9.71776-0.18432 1.95072-0.18432 3.5072 0.03072 5.44256 0 0 0.65024 5.6576 0.96768 6.80448 0.09216 1.60256 0.48128 3.93728 0.95232 6.04672L98.7648 548.13696c0.4096 1.8176 0.96768 3.56864 1.73568 5.29408 0.39424 1.3312 0.86016 2.57024 1.26976 3.5072 76.9792 168.17152 242.43712 281.21088 411.78112 281.21088 169.43616 0 334.94016-112.95232 410.93632-279.33184 1.1776-2.18112 1.93024-4.23424 2.45248-6.02112 0.24064-0.5376 0.45056-1.02912 0.59904-1.5104 1.3312-3.87072 1.60256-7.22944 1.4848-7.22944L928.99328 544.09216zM513.53088 671.4368c-76.60032 0-138.9568-62.35648-138.9568-138.9568 0-76.61056 62.35648-138.94144 138.9568-138.94144S652.48768 455.86432 652.48768 532.48C652.48768 609.08032 590.1312 671.4368 513.53088 671.4368z"
							 p-id="13069" fill="#282828"></path>
						</svg> -->
						<svg t="1607608018202" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13340"
						 width="16" height="16">
							<path d="M928.99328 544.09216c0.54272-2.688 1.3568-6.71232 1.3568-11.52 0-5.00736-0.8448-9.1904-1.08544-9.69216-0.18432-2.47808-1.024-6.99904-1.81248-9.37472-0.20992-0.5888-0.4864-1.20832-0.72704-1.80224-0.42496-1.36192-0.87552-2.69312-1.38752-3.74784-76.8768-168.13568-242.37568-281.14432-411.78112-281.14432-169.37472 0-334.86336 112.90112-411.38688 280.13056-0.9216 1.81248-1.57696 3.55328-1.98144 5.06368-0.1792 0.49664-0.39424 0.96768-0.55808 1.40288-1.5104 4.43392-1.54112 7.51616-1.42336 6.71744-0.71168 3.2512-1.36192 9.71776-1.36192 9.71776-0.18432 1.95072-0.18432 3.5072 0.03072 5.44256 0 0 0.65024 5.6576 0.96768 6.80448 0.09216 1.60256 0.48128 3.93728 0.95232 6.04672L98.7648 548.13696c0.4096 1.8176 0.96768 3.56864 1.73568 5.29408 0.39424 1.3312 0.86016 2.57024 1.26976 3.5072 76.9792 168.17152 242.43712 281.21088 411.78112 281.21088 169.43616 0 334.94016-112.95232 410.93632-279.33184 1.1776-2.18112 1.93024-4.23424 2.45248-6.02112 0.24064-0.5376 0.45056-1.02912 0.59904-1.5104 1.3312-3.87072 1.60256-7.22944 1.4848-7.22944L928.99328 544.09216zM513.53088 671.4368c-76.60032 0-138.9568-62.35648-138.9568-138.9568 0-76.61056 62.35648-138.94144 138.9568-138.94144S652.48768 455.86432 652.48768 532.48C652.48768 609.08032 590.1312 671.4368 513.53088 671.4368z"
							 p-id="13341" fill="#777777"></path>
						</svg>
						{{content.see}}
					</p>
					<p>
						<svg t="1607608227935" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14169"
						 width="16" height="16">
							<path d="M700.856275 155.542542c-74.768891 0-144.295384 72.696696-190.046381 127.260405C465.073224 228.226958 395.563104 155.542542 320.754305 155.542542c-134.789892 0-244.443291 105.780185-244.443291 235.799424 0 77.56968 39.277562 131.98808 70.844511 175.712931 91.752704 126.998439 322.464249 285.038872 332.234777 291.701618 9.410324 6.414083 20.424168 9.629311 31.401174 9.629311 11.005658 0 21.998013-3.215228 31.397081-9.629311 9.782807-6.662747 240.514819-164.703179 332.23887-291.701618 31.586392-43.723827 70.873164-98.143251 70.873164-175.712931C945.299567 261.321704 835.645145 155.542542 700.856275 155.542542L700.856275 155.542542zM700.856275 155.542542"
							 p-id="14170" fill="#777777"></path>
						</svg>
						{{content.like}}
					</p>
				</div>
				<div class="content-info">
					<img class="content-img" :src="content.img">
				</div>
			</div>
			<div ref="silder" class="content-right">
				
			</div>
		</div>
		<xjFooter></xjFooter>
	</div>
</template>
<style scoped>

</style>

<script>
	import xjHeader from '../../common/template/xj-header.vue';
	import xjFooter from '../../common/template/xj-footer.vue';
	export default {
		components: {
			xjHeader,
			xjFooter
		},
		data() {
			return {
				content: {
					id: 1,
					title: '晚上 湖水 竹排 渔船 鸟 灯 好看4k壁纸',
					time: '2020-20-20',
					see: 1280,
					like: 458,
					img: require("../../assets/images/img/kk.jpg"),
					type: 'jpg',
					category: '4K风景',
					px: '3180*2160',
					size: '1.3M'
				}
			}
		},
		mounted(e) {
			// 计算页面高度
			var clientHeight = localStorage.getItem('clientHeight');
			if (clientHeight < 600) {
				this.$refs.silder.style.height = '40rem';
			} else {
				var userHeight = (clientHeight - 8.5 * 16) + "px";
				this.$refs.silder.style.height = userHeight;
			}

		},
		destroyed() {},
		methods: {

		}
	}
</script>

<style scoped>
	.info-content {
		display: flex;
	}

	.content-left {
		width: 75%;
		display: flex;
		flex-direction: column;
	}

	.content-right {
		width: 25%;
	}

	.content-title {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem;
		margin-top: .5rem;
	}

	.content-item {
		display: flex;
		margin: 1rem 0;
	}

	.content-item p {
		display: flex;
		color: #777777;
		margin-right: .5rem;
	}

	.content-info {
		text-align: center;
	}

	.content-img {
		width: auto;
		height: auto;
		max-width: 95%;
		margin-left: auto;
		margin-right: auto;
		/* object-fit: contain; */
	}
</style>
