<template>
	<div class="header">
		<div class="head">
			<div class="header-left">
				<div class="logo" @click="go('index')">
					<img :src="HeaderLogo">
				</div>
				<ul class="nav">
					<li class="nav-li" v-for="(item,key) in HeaderMenu" @click="go(item.link)">

						<p v-bind:class="[item.array?'nav-more':'']">{{item.text}}</p>

						<ul class="nav-more-list" v-if="item.array">
							<li class="nav-more-li" v-for="(i,k) in item.array" @click.stop="go(i.link)">
								<p>{{i.text}}</p>
							</li>
						</ul>

					</li>
				</ul>
			</div>
			<div class="header-right">
				<div class="search">
					<input type="text" class="s-text" placeholder="搜索"></input>
					<input type="button" class="s-button"></input>
				</div>

				<div class="user">
					<span class="reg-log" @click="remove">
						清除
					</span>
					<!-- $global.hasEnter -->
					<div v-if="user.hasEnter&&!pathUser" class="avatar">
						<img :src="user.userInfo.avatar" class="userimg" @click="go('user')">
					</div>
					<span v-if="!user.hasEnter&&!pathUser" class="reg-log" @click="loginShow">
						登录
					</span>
				</div>
			</div>
		</div>
		<!-- 遮罩-->
		<div class="mask" v-show="isShow">
			<div class="login-box">
				<!-- 关闭 -->
				<div class="close-reg" @click="loginShow">×</div>
				<!-- 账号 -->
				<div class="login-form">
					<input v-if="loginType!=2" type="text" v-model="account" placeholder="账号"></input>
					<input v-if="loginType!=2" type="password" v-model="password" placeholder="密码"></input>

					<!-- 注册-确认密码 -->
					<input v-if="loginType==1" type="password" v-model="canPassword" placeholder="确认密码"></input>
					<!-- 注册、找回密码-邮箱 -->
					<input v-if="loginType!=0" type="email" v-model="email" placeholder="邮箱"></input>

					<!-- 注册、登录、找回-验证 -->
					<!-- <input type="password" class="" placeholder="验证"></input> -->
				</div>
				<!-- 登录 -->
				<div class="login-but">
					<input v-if="loginType==0" type="button" value="登录" @click="login"></input>
					<input v-if="loginType==1" type="button" value="注册" @click="login"></input>
					<input v-if="loginType==2" type="button" value="找回密码" @click="login"></input>
				</div>
				<!-- 注册，找回密码，第三方 -->
				<div class="login-other">
					<p v-if="loginType!=0" @click="changType(0)">立即登录</p>
					<p v-if="loginType!=1" @click="changType(1)">注册账号</p>
					<p v-if="loginType!=2" @click="changType(2)">找回密码</p>
					<!-- <p>三方登录</p> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		// 不接受外来数据，独立为一个模块
		// props: {
		// 	Menu: {
		// 		type: Array,
		// 		default: [{
		// 			text: "暂无"
		// 		}]
		// 	},
		// 	Logo: {
		// 		type: String,
		// 		default: "../../assets/images/Fate_ps.jpg"
		// 	},
		// },
		data() {
			return {
				// 请求数据
				user: {
					hasEnter: false,
					token: '',
					userInfo: {}
				},
				HeaderMenu: [{
						id: 0,
						text: "最新",
						link: "test"
					},
					{
						id: 1,
						text: "分类",
						link: "test"
					},
					{
						id: 2,
						text: "尺寸",
						link: "test"
					},
					{
						id: 3,
						text: "更多更多更多更多更多更多",
						link: "",
						array: [{
							id: 5,
							text: "2K5",
							link: "test"
						}, {
							id: 6,
							text: "4K6",
							link: "test"
						}, {
							id: 7,
							text: "8K7",
							link: "test"
						}]
					},
					{
						id: 4,
						text: "更多更多更多更多更多更多",
						link: "",
						array: [{
							id: 8,
							text: "2K8",
							link: "test"
						}, {
							id: 9,
							text: "4K4K4K4K4K4K4K4K4K4K4K4K4K9",
							link: "test"
						}, {
							id: 10,
							text: "8K10",
							link: "test"
						}]
					},
				],
				HeaderLogo: require("../../assets/images/nes.png"), //图标
				isShow: false, //登录显示控制
				loginType: 0, // 0登录1注册2找回
				account: '', //账号
				password: '', //密码
				canPassword: '', //确认密码
				email: '', //邮箱
				pathUser: 0, //user路径中不展示用户头像和登录按钮
				userAvatar: require("../../assets/images/Fate_ps.jpg"), //图标
			}
		},
		created() {
			if (this.$router.showLogin) {
				this.isShow = true;
			}
			if (this.$route.path == "/user") {
				this.pathUser = 1;
			}
			var user = JSON.parse(localStorage.getItem('user'));
			if (user) {
				this.user = user;
			}
		},
		mounted() {},
		methods: {
			remove() {
				var user = {
					hasEnter: false,
					token: '',
					userInfo: {}
				};
				localStorage.removeItem('user');
				this.user = user;
				console.log(this.user);
				this.$router.push('index');
			},
			go(link) {
				if (link) {
					if (link == "index" && (this.$route.path == "/" || this.$route.path == "/index")) {
						return;
					}
					this.$router.push(link);
				}
			},
			loginShow() {
				this.isShow = !this.isShow;
			},
			login() {
				var that = this,
					loginType = that.loginType,
					account = that.account,
					password = that.password,
					canPassword = that.canPassword,
					email = that.email;
				switch (loginType) {
					case 0: //登录
						// 发起登录后注册用户信息
						that.user.hasEnter = true;
						that.user.token = '2xMF$F6@_ZJbtinZabsIsWXo8QyJJ7@l';
						that.user.userInfo = {
							id: 1001,
							account: '悬剑',
							nickname: '玄鉴',
							email: '2216153486@qq.com',
							level: '会员',
							mobile: '18686868866',
							avatar: that.userAvatar,
							gender: 1,
							birthday: '2020-02-02',
							bio: '鲸落南北，鲨掉东西',
							score: 1210,
						};
						// sessionStorage
						localStorage.setItem('user', JSON.stringify(that.user));
						this.loginShow();
						break;
					case 1: //注册
						console.log(account);
						console.log(password);
						console.log(canPassword);
						console.log(email);
						console.log('注册');
						break;
					case 2: //找回密码
						console.log(email);
						console.log('找回密码');
						break;
				};
			},
			changType(type) {
				this.loginType = type;
			}


			// register() {
			// 	console.log('注册')
			// }
			// 向父组件传值
			// consolex() {
			// 	this.$emit('consolexx', 'xx')
			// }
		}
	}
</script>

<style>
	.header {
		min-width: 75rem;
		margin-bottom: 0.5rem;
	}

	.head {
		width: 75rem;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.header-left,
	.header-right,
	.search {
		display: flex;
	}

	.logo {
		display: flex;
		align-items: center;
	}

	/* 圆 */
	/* .logo img {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		margin-right: 1rem;
	} */
	.logo img {
		width: 5rem;
		height: 2.5rem;
		margin-right: 1rem;
		cursor: pointer;
	}

	.nav {
		display: flex;
	}

	.nav-li {
		padding: 0 1rem;
		line-height: 3rem;
		position: relative;
		cursor: pointer;
		user-select: none;
	}

	.nav-li:hover .nav-more-list {
		display: block;
		z-index: 1;
	}

	.nav-more-list {
		display: none;
		position: absolute;
		margin: 0 auto;
		left: 0;
		right: 0;
	}

	.nav-more-li {
		padding: 0 1rem;
		user-select: none;
	}


	.nav-more-li p {
		word-wrap: break-word;
		word-break: break-all;
	}

	.search {
		align-items: center;
	}

	.search input {
		height: 2rem;
		border: 0;
	}

	.search input::-webkit-input-placeholder {
		font-size: 1rem;
	}

	.s-text {
		padding: 0 .5rem;
		font-size: 1.2rem;
		width: 10rem;
	}

	.s-button {
		width: 2rem;
		background-image: url(../../assets/images/icon/search.png);
		background-size: cover;
	}

	.user {
		display: flex;
		align-items: center;
	}

	.reg-log {
		height: 2rem;
		display: flex;
		align-items: center;
		margin-left: .2rem;
		padding: 0 .5rem;
		cursor: pointer;
		line-height: 21px;
	}


	.mask {
		/* display: none; */
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		/* opacity: 0.5; */
		/*会影响所有子节点*/
		background: rgba(0, 0, 0, 0.5);
		z-index: 3;
	}

	.login-box {
		position: absolute;
		margin: 10rem auto 0;
		width: 36rem;
		height: 23rem;
		top: 50%;
		left: 50%;
		margin: -10.5rem 0 0 -18rem;
		overflow: hidden;
	}

	.close-reg {
		font-family: fantasy;
		position: absolute;
		width: 1.2rem;
		height: 1.2rem;
		font-size: 1.5rem;
		line-height: 1.2rem;
		right: .4rem;
		top: .4rem;
		text-align: center;
		transition: all 0.2s ease 0s;
	}

	.close-reg:hover {
		cursor: pointer;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}

	.login-form {
		display: flex;
		flex-direction: column;
		margin: 4rem 10rem 0;
	}

	.login-form input {
		border: 0;
		height: 2rem;
		width: 16rem;
		padding: 2px 8px;
		margin-bottom: 1rem;
	}

	.login-but {
		margin: 0 10rem 0;
	}

	.login-but input {
		/* border: 0; */
		border-style: outset;
		height: 2rem;
		/* width: 16.625rem; */
		width: 17rem;
	}

	.login-other {
		display: flex;
		margin-top: 2rem;
		position: absolute;
		bottom: 1rem;
	}

	.login-other p {
		/* text-decoration: underline; */
		border-bottom-style:solid;
		border-bottom-width: 1px;
		font-size: .8rem;
		margin-left: 1rem;
		cursor: pointer;
	}

	.avatar {
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-left: 1rem;
	}

	.avatar img {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
	}
</style>
